import React from "react"
import {StaticImage} from "gatsby-plugin-image"
import {Badge, Button, Container} from "react-bootstrap";
import Prijs from "gatsby-theme-nieuwbouw/src/components/Prijs";
import {Bouwtype, Project} from "gatsby-theme-nieuwbouw/src/graphqlTypes";

import {graphql} from "gatsby";
import Woningtypes from "../components/Woningtypes";
import {IPage} from "../types";
import Page from "gatsby-theme-nieuwbouw/src/components/Page";

interface Props {
    data: PageQueryData
}
export default ({ data }: Props) => {
    return (
        <Page pageTitle={data.file.childMarkdownRemark.frontmatter.title} pageDescription={data.file.childMarkdownRemark.frontmatter.description}>
            <div className={'position-relative text-center'}>
                {data.project.hoofdfoto &&
                    <StaticImage
                        alt={'artist impression'}
                        src={'../img/index/Vaartsche-Weelde-uitbouw-HR@2x.png'}
                        style={{ maxHeight: 'calc(100vh - 4rem)', minHeight: '800px' }}
                    />
                }
                <Container fluid={"xl"}
                           style={{
                               position: 'absolute',
                               top: '75%',
                               left: '50%',
                               transform: 'translate(-50%, -50%)'
                           }}
                >
                    <h1
                        className={'text-white font-weight-bolder mb-3'}
                        style={{
                            textShadow: '0px 0px 15px #00000091'
                        }}
                    >
                        Riant wonen in een<br/> waterrijke omgeving
                    </h1>

                    <Badge variant={'primary'}
                           className={'text-uppercase text-white'}
                           style={{
                               fontSize: 18
                           }}
                    >
                        {data.project.project.algemeen.koopaanneemsomVanaf &&
                        <>
                            Verkoop gesloten
                        </>
                        }
                    </Badge>
                </Container>
            </div>
            <section
                className={'py-5'}
                style={{
                    backgroundSize: 'cover',
                }}
            >
                <Container
                    fluid={'xl'}
                    style={{
                        maxWidth: 572
                    }}
                    className={'text-center'}
                >
                    <span
                        className={'font-weight-bold text-uppercase roboto'}
                    >Verkoop gesloten</span>
                    <hr
                        className={'mt-4 mb-5'}
                        style={{
                            width: 94,
                        }}
                    />
                    <header>
                        <h2>Wonen in een decor van groen en water</h2>
                    </header>
                    <p>
                        Op een van de meest prominente plekken van de Schelpenbuurt verrijst het project Vaartsche Weelde. In Vaartsche Weelde worden
                        17 woningen aan direct vaarwater gerealiseerd, met een eigen aanlegsteiger. Vaar de sluis tussen Havenkwartier en Schelpenbuurt
                        door en je wordt verwelkomd door een select aantal prachtige statige en uiterst duurzame kadewoningen.
                    </p>
                    <Button
                        variant={'secondary'}
                        href={'#inschrijven'}
                        size={'lg'}
                        className={'text-uppercase rounded-pill text-primary'}
                    >
                        Schrijf je direct in
                    </Button>
                </Container>
            </section>
            <Container
                as={'section'}
                className={'py-5'}
            >
                <Woningtypes woningen={false} />
            </Container>
        </Page>
    )
}

interface PageQueryData {
    file: {
        childMarkdownRemark: IPage
    }
    project: Project
    a: Bouwtype
    b: Bouwtype
    c: Bouwtype
}
export const pageQuery = graphql`
    fragment Page on MarkdownRemark {
        html
        frontmatter {
            title
            description
        }
    }
    fragment BouwtypeCard on Bouwtype {
        algemeen {
            omschrijving
            woonhuistype
            koopaanneemsomVanaf
        }
        diversen {
            objectcode
        }
        fields {
            slug
        }
        hoofdfoto {
            ...Image
        }
    }
    query {
        file(name: {eq: "home"}) {
            childMarkdownRemark {
                ...Page
            }
        }
        project {
            hoofdfoto {
                ...Image
            }
            project {
                algemeen {
                    koopaanneemsomVanaf
                }
            }
        }
        a: bouwtype(diversen: {objectcode: {eq: "O100087"}}) {
            ...BouwtypeCard
        }
        b: bouwtype(diversen: {objectcode: {eq: "O100088"}}) {
            ...BouwtypeCard
        }
        c: bouwtype(diversen: {objectcode: {eq: "O100089"}}) {
            ...BouwtypeCard
        }
    }
`
