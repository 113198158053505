import { Bouwtype } from "gatsby-theme-nieuwbouw/src/graphqlTypes";
import React from "react";
import {Button, Card, Col, Row} from "react-bootstrap";
import {GatsbyImage} from "gatsby-plugin-image";
import Prijs from "gatsby-theme-nieuwbouw/src/components/Prijs";
import {Link} from "gatsby";
import Badges from "gatsby-theme-nieuwbouw/src/components/nummer/Badges";
import TypeA from "./types/A";
import TypeB from "./types/B";
import TypeC from "./types/C";

interface WoningtypeProps {
    woningen: boolean
    bouwtype: Bouwtype
    markdown: {
        id: string
        html: string
        excerpt: string
        frontmatter: {
            title: string
            description: string
            soort: string
            extra: string
            subtitle: string
            tagline: string
        }
    }
}
export const Woningtype = ({bouwtype, markdown, woningen}: WoningtypeProps) => {
    const {frontmatter} = markdown;
    return (
        <Card className={'h-100 border-0 bg-transparent'}>
            <Card.Header className={'bg-transparent'}>
                <Card.Title
                    as={'h3'}
                    className={'font-weight-bold'}
                >
                    {frontmatter.title}
                </Card.Title>
                <Card.Subtitle
                    as={'h3'}
                    className={'text-primary font-weight-bold'}
                >
                    {frontmatter.subtitle}
                </Card.Subtitle>
            </Card.Header>
            {bouwtype.hoofdfoto &&
            <GatsbyImage alt={'artist impression'} image={bouwtype.hoofdfoto.localFile.childImageSharp.gatsbyImageData} />
            }
            <Card.Body>
                <Card.Title className={'font-weight-bold'}>
                    {frontmatter.title}
                </Card.Title>
                <div dangerouslySetInnerHTML={{__html: markdown.html}} />
            </Card.Body>
            <Card.Footer
                className={'border-0 bg-transparent'}
            >
                {woningen &&
                <div className={'mb-3'} style={{
                    minHeight: 100
                }}>
                    <h6 className={"font-weight-bold"}>Bouwnummers:</h6>
                    <Badges bouwnummers={bouwtype.bouwnummers} />
                </div>
                }
                {bouwtype.algemeen.koopaanneemsomVanaf &&
                <div className={'text-uppercase text-primary font-weight-bold roboto my-3'}>v.a. <Prijs prijs={bouwtype.algemeen.koopaanneemsomVanaf}/> v.o.n.</div>
                }
                {bouwtype.algemeen.omschrijving &&
                <Button
                    as={Link}
                    className={'rounded-pill text-secondary text-uppercase'}
                    variant={'outline-primary'}
                    size={'lg'}
                    to={`/woningen/type-${bouwtype.algemeen.omschrijving.toLowerCase()}`}
                >
                    Bekijk type {bouwtype.algemeen.omschrijving}
                </Button>
                }
            </Card.Footer>
        </Card>
    )
}

interface WoningtypesProps {
    woningen: boolean
}
const Woningtypes = ({woningen}: WoningtypesProps) => {
    return (
        <Row
            className={'border-between'}
        >
            <Col lg={4} className={'mb-5'}>
                <TypeA woningen={woningen} />
            </Col>
            <Col lg={4} className={'mb-5'}>
                <TypeB woningen={woningen} />
            </Col>
            <Col lg={4} className={'mb-5'}>
                <TypeC woningen={woningen} />
            </Col>
        </Row>
    )
}

export default Woningtypes
