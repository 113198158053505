import {graphql, useStaticQuery} from "gatsby";
import React from "react";
import {Woningtype} from "../Woningtypes";

interface TypeProps {
    woningen: boolean
}
const TypeA = ({woningen}: TypeProps) => {
    const data = useStaticQuery(graphql`
        query {
            file(name: {eq: "type-a"}) {
                childMarkdownRemark {
                    ...TypePage
                }
            }
            bouwtype(diversen: {objectcode: {eq: "O100087"}}) {
                ...Bouwtype
            }
        }
    `)
    return (
        <Woningtype bouwtype={data.bouwtype} markdown={data.file.childMarkdownRemark} woningen={woningen} />
    )
}

export default TypeA
